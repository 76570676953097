import { FaCheck, FaExclamationCircle } from "react-icons/fa";
type BadgeType = "WARNING" | "VERIFIED";
interface VerifiedBadgeProps {
  title: string;
  type: BadgeType;
}
const VerifiedBadge: React.FC<VerifiedBadgeProps> = ({title, type}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: type === "WARNING" ? "rgb(255, 254, 212)" : "hsl(139, 75.30%, 85.70%)",
        border: `1px solid ${type === "WARNING" ? "rgb(130, 83, 0)" : "rgb(1, 159, 72)"}`,
        borderWidth:1,
        color: type === "WARNING" ? "rgb(130, 83, 0)" : "green",
        borderRadius: "12px",
        padding: "2px 5px",
        fontWeight:'bold',
        fontSize:10,
        margin:0,
      }}
    >
      {title ?? "Verified"}
      {type === 'VERIFIED' &&<FaCheck style={{ marginLeft: "8px", fontSize: "10px" }} size={10}/>}
      {type === 'WARNING' &&<FaExclamationCircle style={{ marginLeft: "8px", fontSize: "10px" }} size={10}/>}
    </div>
  );
};
export default VerifiedBadge;