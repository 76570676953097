import React from 'react';
import { useField } from 'formik';
// @ts-ignore
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { ErrorM } from './ErrorM';

type ParsedNumber = {
    countryCallingCode: string | null;
    nationalNumber: string | null;
    number: string | null;
    metadata: {
        country_calling_codes: { [key: number]: string[] };
        countries: any;
        nonGeographic: any;
    };
};

export const FormPhoneNumberField = (props: {
    field: string;
    label?: string;
    labelExtraInfo?: string;
    sideBySide?: boolean;
    required?: boolean;
    classname?: string;
}) => {
    const [field, meta, helpers] = useField(props.field);
    const showError = meta.touched && meta.error;

    const getPhoneCountry = (phone: string | null) => {
        if (!phone) return 'US';
        const parsedNumber = parsePhoneNumber(field.value);
        const metaData = parsedNumber?.getMetadata()
        if (
            !parsedNumber ||
            !metaData ||
            !parsedNumber?.countryCallingCode
        )
            return 'US';

        const callingCodes = metaData.country_calling_codes;
        const countries = callingCodes[parsedNumber.countryCallingCode as any];

        if (!countries || countries.length === 0) return 'US';

        return countries[0];
    };
    
    const defaultCountry = getPhoneCountry(field.value) ?? "US";

    return (
        <div
            className={`FormBox PhoneNumberBox ${props.sideBySide && 'SideBySide'} ${
                props.classname || ''
            }`}
        >
            <div className="FormLabel">
                <label>
                    {props.label}
                    {props.labelExtraInfo && (
                        <span className="Optional"> - {props.labelExtraInfo}</span>
                    )}
                </label>
            </div>
            <div onBlur={() => helpers.setTouched(true)}>
                <PhoneInput
                    className="EditBox"
                    value={field.value}
                    onChange={(value: any) => {
                        value && helpers.setValue(value);
                    }}
                    defaultCountry={defaultCountry}
                    name={props.field}
                    numberInputProps={{
                        className: `EditBox${showError ? ' ErrorInput' : ''}`,
                    }}
                    required={props.required}
                />
                <ErrorM name={props.field} />
            </div>
        </div>
    );
};
