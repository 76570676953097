import {
    WrappedFormSingleSelectField as FormSingleSelectField,
} from 'components/form/FormSingleSelectField';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../api';
import FormTextField from '../../../components/form/FormTextField';
import { RadioButtons } from '../../../components/radiobuttons/radiobuttons';
import { endpoints, isProduction } from '../../../endpoints.config';
import { useGetFiatAccountDetails } from '../../../helpers/useGetFiatAccountDetails';
import { selectPayeeSubpage } from '../../../reducers/payee';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import { GeneralError } from '../../../components/GeneralError/GeneralError';
import { Toast } from '../../../helpers/toast';
import { processErrors } from '../../../helpers/categoryHelpers/processErrors';
import Button from '../../../components/button/Button';
import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import { AddressLookup } from './MakePayment/AddressLookup/AddressLookup';
import {
    CountryOptions,
    useFetchPayeeCountries,
    useFetchPayeeCurrencies,
} from './MakePayment/MakePayment';
import _ from 'lodash';
import {
    useCreatePayeeInitalValues,
    useIsFinancialInstitution,
    usePayeeValidationSchema,
} from './helpers';
import { FormDateField } from '../../../components/form/FormDateField';
import { convertLocaleDateToUtcDate } from '../../../helpers/convertLocaleDateToUtcDate';
import { BankAndAccountValidationResponse } from './MakePayment/amountPurposeModel';
import { debounce } from 'lodash';
import { ThreeBounce } from 'better-react-spinkit';
import VerifiedBadge from 'components/widgets/VerifiedBadge';
import { SelectOption } from 'components/form/Dropdown';
import { TransferType } from 'pages/crypto/Crypto';
export type EditPayeeResponse = {
    id: number;
    accountName: string;
    name: string;
    payeesReference: string;
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postcode: string;
    addressCountryCode: string;

    type: PayeeType;
    achAccountNumber: string;
    achRoutingNumber: string;
    swiftNumber: string;
    intermediaryBic: string;
    iban: string;
    bankName: string;
    countryCode: string;

    accountType: string;
    addDate: string;
    addedBy: string;
    allowedTransferTypes: string;
    bInternational: boolean;
    bPullAvailable: boolean;
    cardLast4Digits: null;
    countryName: string;
    currencyCode: string;

    transmitterType: TransmitterType;
    transmitterName: string;
    transmitterDateOfBirth?: string;
    transmitterFirstPartyTransfer: boolean;
    transmitterAccountNumber: string;
    transmitterAddressLine1: string;
    transmitterAddressLine2: string;
    transmitterCountryCode: string;
    transmitterPostcode: string;
    transmitterState: string;
    transmitterTownCity: string;
};

export enum PayeeType {
    Personal = 'Personal',
    Company = 'Company',
}
export enum TransmitterType {
    Personal = 'Individual', // Client quoted this value hence not using "Personal"
    Company = 'Company',
}

export type TransmitterInfo = {
    firstPartyTransfer: boolean;
    type: TransmitterType | null;
    name: string;
    dateOfBirth?: string;
    accountNumber?: string;
    addressLine1: string;
    addressLine2?: string;
    townCity: string;
    state: string;
    postcode: string;
    countryCode: string;
};

export type PayeeFormState = {
    payeeType: PayeeType;
    bankName: string;
    countryCode: string;
    accountName: string;
    name: string;
    payeesReference: string;

    routingNumber: string;
    swiftNumber: string;
    intermediaryBic: string;
    accountNumber: string;

    iban: string;

    bFinancialInstitution: boolean;
    transmitter: TransmitterInfo | null;
    currencyCode: string | null;
} & PayeeAddress;

export type PayeeAddress = {
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postCode: string;
    addressCountryCode: string;
};

export const EMPTY_PAYEE_VALUES: PayeeFormState = {
    payeeType: PayeeType.Personal,
    countryCode: '',
    accountName: '',
    name: '',
    payeesReference: '',
    routingNumber: '',
    swiftNumber: '',
    intermediaryBic: '',
    accountNumber: '',
    iban: '',
    bankName: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    state: '',
    postCode: '',
    addressCountryCode: '',
    bFinancialInstitution: false,
    transmitter: null,
    currencyCode: '',
};
export const EMPTY_TRANSMITTER_INFO: TransmitterInfo = {
    firstPartyTransfer: false,
    type: null,
    name: '',
    dateOfBirth: undefined,
    accountNumber: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    state: '',
    postcode: '',
    countryCode: '',
};

type Props = {
    onBack: () => void;
};

export const EditPayee: React.FC<Props> = ({ onBack }) => {
    const { colors } = useTheme();

    const { payee } = useSelector(selectPayeeSubpage);

    const { availableCountries, errorCountries } = useFetchPayeeCountries();
    const { availableCurrencies } = useFetchPayeeCurrencies();
   
    const { initialValues, loading, error } = useCreatePayeeInitalValues(payee?.payees__Id);

    const onSubmit = async (values: PayeeFormState, helpers: FormikHelpers<PayeeFormState>) => {
        try {
            const { postCode, ...payload } = values;
           
            const res = await api.post<ApiResponse<any>>(endpoints.accounts.editPayee, {
                id: payee?.payees__Id,
                postcode: postCode,
                ...payload,
                transmitter: payload.transmitter
                    ? {
                          ...payload.transmitter,
                          dateOfBirth: convertLocaleDateToUtcDate(payload.transmitter.dateOfBirth),
                      }
                    : null,
            });

            if (res.data.status === '1') {
                Toast.openSuccessToast('Payee details updated');
                onBack();
            } else {
                throw new Error();
            }
        } catch (err) {
            if (isAxiosErrorHandled(err)) {
                const { errors } = err.response.data;
                if (errors.length > 0) {
                    helpers.setSubmitting(false);
                    const errorsObj = processErrors(errors);
                    helpers.setErrors(errorsObj);

                    if (errorsObj['error_Label']) {
                        Toast.openGenericErrorToast();
                    }
                } else {
                    Toast.openErrorToast('Failed to update details');
                }
            } else {
                Toast.openErrorToast('Failed to update details');
            }
        }
    };

    const validationSchema = usePayeeValidationSchema(errorCountries);

    const accountDetails = useGetFiatAccountDetails();

    if (loading)
        return (
            <div className="EditPayeePage">
                <div className="Loading">
                    <FoldingCube color={colors.first} size={80} />
                    <p>Loading payee</p>
                </div>
            </div>
        );
    if ((!loading && error) || !initialValues)
        return (
            <div className="EditPayeePage">
                <div className="Error">
                    <GeneralError message="Unable to load payee details" />
                </div>
            </div>
        );

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Form className="EditPayeePage">
                    <h1>Edit payee details</h1>

                    <>
                        <PayeeForm
                            productType={accountDetails?.productDisplayName}
                            isEdit
                            availableCountries={availableCountries}
                            availableCurrencies={availableCurrencies}
                        />
                        <UpdateWarning />
                        <Button type="submit" disabled={isSubmitting} color="third">
                            Save
                        </Button>
                    </>
                </Form>
            )}
        </Formik>
    );
};

type PayeeFormProps = {
    availableCountries: CountryOptions[];
    availableCurrencies: SelectOption[];
    isEdit?: boolean;
    fieldnamePrefix?: string;
    defaultShowLookup?: boolean;
    productType?: ProductType;
    sourceAccountCurrencyCode?: string;
};

export const PayeeForm: React.FC<PayeeFormProps> = ({
    isEdit = false,
    availableCountries,
    availableCurrencies,
    fieldnamePrefix,
    defaultShowLookup,
    productType,
    sourceAccountCurrencyCode
}) => {
    const { values, isSubmitting, setFieldValue } = useFormikContext<
        PayeeFormState & { [key: string]: any }
    >();
    const prevValuesRef = useRef<PayeeFormState & { [key: string]: any }>();
    const isDBS = productType === ProductType.DBS || productType === ProductType.DBS_TMP;
    const isCRB = productType === ProductType.CRB;
    const isGLDB = productType === ProductType.GLDB;
    const isBankingCircle = productType === ProductType.BC;
    const [inputRoutingNumber, setInputRoutingNumber] = useState<String>()
    const [inputSwiftNumber, setInputSwiftNumber] = useState<String>()
    const [validatingAccount, setValidatingAccount] = useState<boolean>(false);

    const [routingValidationStatus, setRoutingValidationStatus] = useState<{message:string, status: boolean, isRTPVarified: boolean | undefined, isWireVarified: boolean | undefined}>();
    const [validationStatus, setValidationStatus] = useState<
        BankAndAccountValidationResponse | undefined
    >();
   // const [payeeNameMessage, setPayeeNameMessage] = useState('Maximum 35 characters');
    const buildFieldname = useCallback(
        (name: string) => (fieldnamePrefix ? `${fieldnamePrefix}.${name}` : name),
        [fieldnamePrefix]
    );

    const [
        { value: countryCode },
        { touched: countryCodeTouched },
        { setTouched: setCountryCodeTouched },
    ] = useField(buildFieldname('countryCode'));


    
    const debouncedFunction = useMemo(() =>
        debounce(async (currentValues, productType) => {
           
            setValidatingAccount(true);
              
            try {
                //let country = availableCountries?.find((country => country.value == currentValues.countryCode))
                //const isoCode2 = country ? (country as unknown as { countryISO2: string }).countryISO2 : currentValues.countryCode;
                const res = await api.post<ApiResponse<any>>(endpoints.accounts.accountValidation, {
                    AccountNumber: currentValues.iban?.length > 0 ? currentValues.iban : currentValues.accountNumber,
                    AccountName: currentValues.accountName,
                    PayeeCountryCode: currentValues.countryCode,
                    //PayeeCountryCodeISO2: isoCode2 ?? currentValues.countryCode,
                    PayeeCurrencyCode: currentValues.currencyCode,
                    productType,
                    Swift: currentValues.swiftNumber,
                    RoutingNumber: currentValues.routingNumber ?? '',

                });

                let detials = res.data?.details
                let isWireVarified = false
                if (detials?.wireBankValidation?.length > 0) {
                    detials.BankValidationOptions = []


                    detials?.wireBankValidation.forEach((element: any) => {

                        detials.BankValidationOptions.push({ value: element.routingNumber, label: element.routingNumber + " (" + element.bankName + ")" })
                        if (element?.routingNumber == currentValues.routingNumber) {
                            isWireVarified = true
                        }
                    });


                } else if (detials?.rtpBankValidation?.length > 0) {
                    detials.BankValidationOptions = []


                    detials?.rtpBankValidation.forEach((element: any) => {

                        detials.BankValidationOptions.push({ value: element.routingNumber, label: element.routingNumber + " (" + element.bankName + ")" })
                    });


                }
                if(detials?.swiftsSuggestions?.length > 0){
                    var swiftsSuggestionsOptions: SelectOption[] = []
                    detials?.swiftsSuggestions.forEach((element: any) => {

                        swiftsSuggestionsOptions.push({ value: element.swift, label: element.swift + " (" + element.bank_name + ")" })
                    });
                   detials.swiftsSuggestionsOptions = swiftsSuggestionsOptions
                }
                let isRTPVarified = false
                if (detials?.rtpBankValidation?.length > 0 && detials?.rtpBankValidation[0].routingNumber == currentValues.routingNumber) {
                    isRTPVarified = true
                }
                
                if (currentValues.routingNumber?.length > 0 && isCRB) {
                    let statusMessage;
                    if (isRTPVarified && isWireVarified) {
                        statusMessage = "Verified routing number for WIRE and RTP.";
                    } else if (isRTPVarified) {
                        statusMessage = "Verified routing number for RTP.";
                    } else if (isWireVarified) {
                        statusMessage = "Verified routing number for WIRE.";
                    } else {
                        statusMessage = "Unable to verify Routing.";
                    }

                    setRoutingValidationStatus({ message: statusMessage, status: isRTPVarified || isWireVarified, isRTPVarified, isWireVarified });
                } else {
                    setRoutingValidationStatus({ message: "", status: false, isRTPVarified: undefined, isWireVarified: undefined });
                }
                
                let nonSupportedTransferTypes: TransferType[] = []
                if (currentValues.routingNumber?.length > 0 && isCRB && !isRTPVarified) nonSupportedTransferTypes.push(TransferType.RTP)
                if (currentValues.routingNumber?.length > 0 && isCRB && !isWireVarified) nonSupportedTransferTypes.push(TransferType.Wire)
                
                if(res.data?.details?.swiftVerification?.swiftDetails)
                {
                    res.data?.details?.swiftVerification?.swiftDetails?.bankName &&
                        setFieldValue(buildFieldname('bankName'),res.data?.details?.swiftVerification?.swiftDetails?.bankName, true);
                    res.data?.details?.swiftVerification?.swiftDetails?.bankCountryISO3 &&
                        setFieldValue(buildFieldname('countryCode'),res.data?.details?.swiftVerification?.swiftDetails?.bankCountryISO3, true);  
                }
               
                setValidationStatus(res.data?.details as any);
                setFieldValue(buildFieldname('nonSupportedTransferTypes'), nonSupportedTransferTypes?.join(","), false);
                if (res.data?.details?.acountValidation?.beneficiary?.bank_account_name)
                    setFieldValue(buildFieldname('accountName'), res.data?.details?.acountValidation?.beneficiary?.bank_account_name, false);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
                setRoutingValidationStatus({ message: "", status: false, isRTPVarified: undefined, isWireVarified: undefined });
                setFieldValue(buildFieldname('nonSupportedTransferTypes'), '', false);
            }
            setValidatingAccount(false);
        }, 2000), [productType, setFieldValue, setRoutingValidationStatus, setValidationStatus,availableCountries]);
    
    const bFinancialInstitution = useIsFinancialInstitution();
    const [payeeNameMessage, setPayeeNameMessage] = useState('Maximum 35 characters');
    const [
        { value: currencyCode },
        { touched: currencyCodeTouched },
        { setTouched: setCurrencyCodeTouched },
    ] = useField(buildFieldname('currencyCode'));
    
   
  
    useEffect(() => {
        if (prevValuesRef.current?.countryCode != values.countryCode 
            || prevValuesRef.current?.currencyCode != values.currencyCode) {
                setValidationStatus(undefined);
                setRoutingValidationStatus({ message: "", status: false, isRTPVarified: undefined, isWireVarified: undefined });
        }
        if (isCRB) {
            // Only validate Routing number in case of USA and USD
            if (values.countryCode == 'USA' && values.currencyCode == 'USD'
                || (values.countryCode == '' && values.currencyCode == '')) {
                if (prevValuesRef.current?.routingNumber !== values.routingNumber) {
                    setValidationStatus(prevState => {
                        if(prevState)
                        {
                            prevState.swiftsSuggestionsOptions = [];
                            prevState.BankValidationOptions = []
                        }
                        return prevState
                    })
                    debouncedFunction(values, productType);
                    return

                }

            } else {
                if ( prevValuesRef.current?.swiftNumber !== values.swiftNumber
                    || prevValuesRef.current?.iban !== values.iban) {
                    if (values.AccountNumber?.length > 7 || values.swiftNumber?.length > 6 || values.iban?.length > 5) {
                        setValidationStatus(prevState => {
                            if(prevState)
                            {
                                prevState.swiftsSuggestionsOptions = [];
                                prevState.BankValidationOptions = []
                            }
                            return prevState
                        })
                        debouncedFunction(values, productType);
                    } else {
                        setValidationStatus(undefined);
                    }
                }

            }
        }
        else if (prevValuesRef.current?.swiftNumber !== values.swiftNumber) {
            debouncedFunction(values, productType);
        }
        prevValuesRef.current = values;

    }, [values.countryCode,
        values.currencyCode,
        values.accountNumber,
        // values.accountName,
        values.swiftNumber,
        values.routingNumber,
        values.iban,
        prevValuesRef,
        debouncedFunction,
        isCRB])



        const onChangeCountry = useCallback(
            (
                value: SelectOption | null,
                setFieldValue?: FormikHelpers<PayeeFormState>['setFieldValue']
            ) => {
                !!value && !countryCodeTouched && setCountryCodeTouched(true);
    
                if (!setFieldValue || !value || !countryCode) return;
                if (value.value === 'USA' && currencyCode === 'USD') {
                    if (!isGLDB) {
                        setFieldValue(buildFieldname('iban'), '', false);
                    }
                    if (!(isDBS || isBankingCircle || isGLDB)) {
                        setFieldValue(buildFieldname('swiftNumber'), '', true);
                        setInputSwiftNumber("");
                    }
                    return;
                }
                setFieldValue(buildFieldname('routingNumber'), '', true);
                setFieldValue(buildFieldname('accountNumber'), '', false);
                setInputRoutingNumber("");
              
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [countryCodeTouched, setCountryCodeTouched, countryCode,currencyCode, isDBS, isGLDB, isBankingCircle]
        );
        const onChangeCurrency = useCallback(
            (
                value: SelectOption | null,
                setFieldValue?: FormikHelpers<PayeeFormState>['setFieldValue']
            ) => {
            
                !!value && !currencyCodeTouched && setCurrencyCodeTouched(true);
    
                if (!setFieldValue) return;
                if (!isCRB) return;
            
                setFieldValue(buildFieldname('iban'), '', false);
                setFieldValue(buildFieldname('swiftNumber'), '', true);
                setInputSwiftNumber("");
                setFieldValue(buildFieldname('routingNumber'), '', true);
                setFieldValue(buildFieldname('accountNumber'), '', false);
                setInputRoutingNumber("");
                setValidationStatus(prevState => {
                    if(prevState)
                    {
                        prevState.swiftsSuggestionsOptions = [];
                        prevState.BankValidationOptions = []
                    }
                    return prevState
                })
            
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [currencyCodeTouched, setCurrencyCodeTouched, currencyCode,countryCode, isCRB, setInputRoutingNumber,setInputSwiftNumber]
            
        );
   

    const handleAccountRoutingChange = useCallback(
        (value: any, { action }: { action: string }) => {
              
            if (action == 'input-change') {
                setFieldValue(buildFieldname('routingNumber'),value, false);
                setInputRoutingNumber(value ?? "")
            } 
        },
        [setInputRoutingNumber]
    );

    const onRoutingNumberSelect = useCallback(
        (
            value: SelectOption | null,
            setFieldValue?: FormikHelpers<PayeeFormState>['setFieldValue']
        ) => {
            if (!setFieldValue || !value) return;
             setFieldValue(buildFieldname('routingNumber'),value.value, false);
            setInputRoutingNumber('')

        },
        [currencyCodeTouched, setCurrencyCodeTouched, currencyCode, isCRB, setInputRoutingNumber]
    );
    
    const handleAccountSwiftChange = useCallback(
        (value: any, { action }: { action: string }) => {
              
            if (action == 'input-change') {
                setFieldValue(buildFieldname('swiftNumber'),value, false);
                setInputSwiftNumber(value ?? "")
            } 
        },
        [setInputSwiftNumber]
    );

    const onSwiftNumberSelect = useCallback(
        (
            value: SelectOption | null,
            setFieldValue?: FormikHelpers<PayeeFormState>['setFieldValue']
        ) => {
            if (!setFieldValue || !value) return;
             setFieldValue(buildFieldname('swiftNumber'),value.value, false);
             setInputSwiftNumber('')

        },
        [currencyCodeTouched, setCurrencyCodeTouched, currencyCode, isCRB, setInputSwiftNumber]
    );

    /**
     * Method to update the payee name on input change.
     * This method calculates the remaining characters and updates the payee name message
     * in real-time as the user types in the input field.
     *
     */
    const handlePayeeNameChange = useCallback(
        (e: { target: { value: any } }) => {
            const value = e.target.value;
            if (value.length > 0) {
                setPayeeNameMessage(`${35 - value.length} characters remaining`);
            } else {
                setPayeeNameMessage('Maximum 35 characters');
            }

            setFieldValue(buildFieldname('name'), value);
        },
        [buildFieldname, setFieldValue]
    );

    const transmitterFieldnamePrefix = fieldnamePrefix
        ? `${fieldnamePrefix}.transmitter`
        : 'transmitter';
    const [{ value: bFirstPartyTransfer }] = useField(
        `${transmitterFieldnamePrefix}.firstPartyTransfer`
    );
    

    const handleFirstPartyTransferUpdated = (val: boolean) => {
        if (!val) return;

        // Reset values if true
        const defaultValues: TransmitterInfo = {
            ...EMPTY_TRANSMITTER_INFO,
            firstPartyTransfer: val,
        };
        setFieldValue(transmitterFieldnamePrefix, defaultValues);
    };
    const [{ value: bTransmitterType }] = useField<TransmitterType>(
        `${transmitterFieldnamePrefix}.type`
    );
    
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
                <h4>Recipient details  </h4>
                <div  style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
                    {validationStatus?.acountValidation?.status === 'not_supported' 
                    || validationStatus?.acountValidation?.status === 'validation_error' && (
                        <VerifiedBadge title="Unable to verify Account" type="WARNING" />
                    )}
                    {validationStatus?.acountValidation?.status === 'verified' && (
                        <VerifiedBadge title="Account Verified" type="VERIFIED" />
                    )}
                    {validationStatus?.swiftVerification?.status === 'verified_swift' && (
                        <VerifiedBadge title="Swift Verified" type="VERIFIED" />
                    )}
                    {validationStatus?.swiftVerification?.status === 'not_verified_swift' && (
                        <VerifiedBadge title="Unable to verify Swift" type="WARNING" />
                    )}
                    {routingValidationStatus?.message && routingValidationStatus?.message?.length > 0 && (
                        <VerifiedBadge title={routingValidationStatus?.message} type={routingValidationStatus.status? "VERIFIED" :"WARNING" }/>
                    )}
                </div>
               
                {validatingAccount && <ThreeBounce />}
            </div>
            <RadioButtons
                options={['Personal', 'Company']}
                fieldname={buildFieldname('payeeType')}
                label="Account Type"
                disabled={isSubmitting || isEdit}
            />
            <div className="Layout">
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('name')}
                    label="Payee Name"
                    tooltip="Name of the individual or business that is the beneficiary"
                    hint={payeeNameMessage}
                    maxLength={35}
                    onChange={handlePayeeNameChange}
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('payeesReference')}
                    label="Payee Reference"
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('bankName')}
                    label="Bank Name"
                    tooltip="Name of the receiving bank"
                />
                <FormSingleSelectField
                    fieldName={buildFieldname('countryCode')}
                    options={availableCountries}
                    label="Bank Country"
                    handleOptionSelected={onChangeCountry}
                    dropdownProps={{ isDisabled: isSubmitting }}
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('accountName')}
                    label="Account Name"
                    tooltip="Name of the account that belongs to the payee. This field may not be seen by a receiving bank."
                />
                <FormSingleSelectField
                    fieldName={buildFieldname('currencyCode')}
                    options={availableCurrencies}
                    label="Account Currency"
                    dropdownProps={{ isDisabled: isSubmitting }}
                    handleOptionSelected={onChangeCurrency}
                />

                {isDBS || isBankingCircle ? (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('accountNumber')}
                            label={'Account Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('intermediaryBic')}
                            label={'Intermediary Swift'}
                        />
                    </>
                ) : isGLDB ? (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('iban')}
                            label={'IBAN'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('intermediaryBic')}
                            label={'Intermediary Swift'}
                        />
                    </>
                ) : isCRB &&
                  (countryCode === 'USA' || !countryCode) &&
                  (currencyCode === 'USD' || !currencyCode) ? (
                    <>
                        {/* <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('routingNumber44')}
                            label={'Routing Number'}
                           // onChange={handleAccountRoutingChange}
                        /> */}
                          <FormSingleSelectField
                                fieldName={buildFieldname('routingNumber')}
                                options={validationStatus?.BankValidationOptions ?? []}
                                label="Routing Number"
                                
                                dropdownProps={{ isDisabled: isSubmitting,
                                    inputValue: inputRoutingNumber,
                                    onInputChange:handleAccountRoutingChange,
                                    placeholder:""
                                } }
                                
                                handleOptionSelected={onRoutingNumberSelect}
                            />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('accountNumber')}
                            label={'Account Number'}
                        />
                    </>
                ) : (
                    <>
                        {/* International CRB */}
                        {/* <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        /> */}
                        <FormSingleSelectField
                                fieldName={buildFieldname('swiftNumber')}
                                options={validationStatus?.swiftsSuggestionsOptions ?? []}
                                label="Swift Number"
                                
                                dropdownProps={{ isDisabled: isSubmitting,
                                    inputValue: inputSwiftNumber,
                                    onInputChange:handleAccountSwiftChange,
                                    placeholder:"",
                                    filterOption:() => true
                                } }
                                
                               handleOptionSelected={onSwiftNumberSelect}
                            />
                             
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('iban')}
                            label={'IBAN'}
                            placeholder={isProduction ? '': "Enter your IBAN (e.g., IJKL55555)"}
                        />
                    </>
                )}
            </div>
            <h4>Recipient address</h4>
            <AddressLookup
                fieldnamePrefix={fieldnamePrefix}
                countryOptions={availableCountries}
                defaultShowLookup={
                    values[fieldnamePrefix ? `${fieldnamePrefix}.addressLine1` : 'addressLine1']
                        ? false
                        : typeof defaultShowLookup === 'boolean'
                        ? defaultShowLookup
                        : !isEdit
                }
            />
            {bFinancialInstitution && (
                <>
                    <h4>Transmitter info</h4>
                    <RadioButtons
                        label="First Party Transfer"
                        options={['Yes', 'No']}
                        booleanField
                        fieldname={`${transmitterFieldnamePrefix}.firstPartyTransfer`}
                        customOnChange={handleFirstPartyTransferUpdated}
                    />
                    {bFirstPartyTransfer === false && (
                        <>
                            <RadioButtons
                                options={['Individual', 'Company']}
                                fieldname={`${transmitterFieldnamePrefix}.type`}
                                label="Type"
                                setToNullWhenOptionDoesNotExist
                            />
                            <FormTextField
                                label="Name"
                                field={`${transmitterFieldnamePrefix}.name`}
                            />
                            {bTransmitterType === TransmitterType.Personal && (
                                <FormDateField
                                    field={`${transmitterFieldnamePrefix}.dateOfBirth`}
                                    sideBySide={false}
                                    label="Date of Birth"
                                    datePickerProps={{
                                        maxDate: new Date(),
                                        showYearDropdown: true,
                                        scrollableYearDropdown: true,
                                        yearDropdownItemNumber: 80,
                                    }}
                                />
                            )}
                            <FormTextField
                                label="Account Number"
                                field={`${transmitterFieldnamePrefix}.accountNumber`}
                                labelExtraInfo="Optional"
                            />
                            <AddressLookup
                                fieldnamePrefix={transmitterFieldnamePrefix}
                                countryOptions={availableCountries}
                                defaultShowLookup={
                                    values[transmitterFieldnamePrefix] &&
                                    values[transmitterFieldnamePrefix].addressLine1
                                        ? false
                                        : typeof defaultShowLookup === 'boolean'
                                        ? defaultShowLookup
                                        : !isEdit
                                }
                                fieldNamesMap={{
                                    addressLine1: 'addressLine1',
                                    addressLine2: 'addressLine2',
                                    townCity: 'townCity',
                                    state: 'state',
                                    postCode: 'postcode',
                                    addressCountryCode: 'countryCode',
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

const UpdateWarning = () => {
    const { values, initialValues } = useFormikContext<PayeeFormState>();

    const { accountNumber, routingNumber, swiftNumber, intermediaryBic, iban } = values;
    const {
        accountNumber: initialAccountNumber,
        routingNumber: initialRoutingNumber,
        swiftNumber: initialSwiftNumber,
        intermediaryBic: initialintermediaryBic,
        iban: initalIban,
    } = initialValues;

    const showWarning =
        accountNumber !== initialAccountNumber ||
        routingNumber !== initialRoutingNumber ||
        swiftNumber !== initialSwiftNumber ||
        intermediaryBic !== initialintermediaryBic ||
        iban !== initalIban;

    return showWarning ? (
        <div className="EditPayeeWarning">
            <WarningIcon width={20} />
            <p>
                Warning! By updating this payee you may cause any pending transactions to them to
                fail
            </p>
        </div>
    ) : null;
};
