import * as Yup from 'yup';

export const fxValidationSchema = (
    purposeIsRequired: boolean,
    otherPurposeCode: string | undefined
) =>
    Yup.object({
        feeId: Yup.string().nullable(true),
        amount: Yup.number()
            .nullable()
            .test('validAmount', 'Please enter a valid amount', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Send' && typeof val !== 'number') {
                    return false;
                }
                return true;
            })
            .test('greaterThanZero', 'Please enter an amount greater than 0', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Send' && typeof val === 'number') {
                    return val > 0;
                }
                return true;
            }),
        estimatedAmount: Yup.number()
            .nullable()
            .test('validAmount', 'Please enter a valid amount', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Receive' && typeof val !== 'number') {
                    return false;
                }
                return true;
            })
            .test('greaterThanZero', 'Please enter an amount greater than 0', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Receive' && typeof val === 'number') {
                    return val > 0;
                }
                return true;
            }),
        destinationAmount: Yup.number()
            .nullable()
            .test('fxAmountValid', 'Please enter a valid amount', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Receive' && typeof val !== 'number') {
                    return false;
                }
                return true;
            })
            .test('greaterThanZero', 'Please enter an amount greater than 0', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Receive' && typeof val === 'number') {
                    return val > 0;
                }
                return true;
            }),
        estimatedDestinationAmount: Yup.number()
            .nullable()
            .test('validAmount', 'Please enter a valid amount', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Send' && typeof val !== 'number') {
                    return false;
                }
                return true;
            })
            .test('greaterThanZero', 'Please enter an amount greater than 0', function (val) {
                const { bFxTransfer, exchangeType } = this.parent;
                if (bFxTransfer && exchangeType === 'Send' && typeof val === 'number') {
                    return val > 0;
                }
                return true;
            }),
        bFxTransfer: Yup.boolean().required('Please select an exchange type'),
        purpose: Yup.string()
            .nullable()
            .test('requiredIfCodesGiven', 'Required', (val) => {
                if (purposeIsRequired) return !!val;
                else return true;
            }),
        purposeOther: Yup.string()
            .nullable()
            .when('purpose', {
                is: (val: any) => val === otherPurposeCode && purposeIsRequired,
                then: (schema) => schema.required('Required').max(50, 'Maximum 50 characters'),
            }),
    });

export const amountPurposeValidationSchema = (
    purposeIsRequired: boolean,
    otherPurposeCode: string | undefined
) =>
    Yup.object({
        feeId: Yup.string().nullable(true),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .required('Please enter an amount')
            .positive('Please enter an amount greater than 0'),
        purpose: Yup.string()
            .nullable()
            .test('requiredIfCodesGiven', 'Required', (val) => {
                if (purposeIsRequired) return !!val;
                else return true;
            }),
        purposeOther: Yup.string()
            .nullable()
            .when('purpose', {
                is: (val: any) => val === otherPurposeCode && purposeIsRequired,
                then: (schema) => schema.required('Required').max(50, 'Maximum 50 characters'),
            }),
    });

export type FXAmountPurposeForm = {
    amount: number;
    estimatedAmount: number | null;
    destinationAmount: number;
    estimatedDestinationAmount: number | null;
    bFxTransfer: boolean;
    exchangeType: 'Send' | 'Receive';
    purpose: string;
    purposeOther?: string | null;
    feeId: string | null;
};

export type AmountPurposeForm = {
    amount: number;
    purpose: string;
    purposeOther?: string | null;
    feeId: string | null;
    bFxTransfer: boolean;
};

export const initialFXValues: FXAmountPurposeForm = {
    amount: 0,
    estimatedAmount: null,
    destinationAmount: 0,
    estimatedDestinationAmount: null,
    bFxTransfer: true,
    exchangeType: 'Send',
    purpose: '',
    purposeOther: null,
    feeId: null,
};

export const initialAmountValues: AmountPurposeForm = {
    amount: 0,
    purpose: '',
    purposeOther: null,
    feeId: null,
    bFxTransfer: false,
};

export interface SwiftDetails {
    bankName: string | null;
    swiftCode: string;
    bankPostCode: string | null;
    bankBranchName: string | null;
    bankCity: string | null;
    bankCountryName: string | null;
    bankCountryISO2?: string | null;
    bankCountryISO3?: string | null;
  }

export interface AccountValidationResponse {
    account_validation_id: string | null;
    status: string;
    beneficiary: Beneficiary | null;
    message: string | null;
    errors: ErrorDetailValidation[];
    swiftDetails?: SwiftDetails;
  }
  export interface BankAndAccountValidationResponse {
    acountValidation: AccountValidationResponse;
    wireBankValidation: any
    rtpBankValidation: any
    BankValidationOptions: any
    swiftVerification: AccountValidationResponse
    swiftsSuggestions: any
    swiftsSuggestionsOptions: any
  }
  export interface Beneficiary {
    bank_account_name: string | null;
  }
  
  export interface ErrorDetailValidation {
    path: string;
    message: string;
  }
export type FXTransferDetails = {
    commandDestinationAmount: number | null;
    commandSourceAmount: number | null;
    estimateDestinationAmount: number;
    estimateSourceAmount: number;
    estimateTotalDestinationAmount: number;
    estimateTotalSourceAmount: number;
    exchangeFeeAmount: number;
    fxFeePercentage: number;
    quotedFXRate: string;
    quotedFXRateID: number;
    quotedFxRateExpiry: string;
    bankAndAccountValidationResponse?: BankAndAccountValidationResponse;
};
