import { Modal } from 'components/modal/Modal';
import { completeUIUpdate } from 'components/notifications/notificationUIUpdateReducer';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';
import api, { ApiResponse, isAxiosErrorHandled, useFetch } from '../../api';
import { endpoints } from '../../endpoints.config';
import Button from '../button/Button';
import { FormDateField } from '../form/FormDateField';
import { FormSingleSelectField } from '../form/FormSingleSelectField';
import FormTextField from '../form/FormTextField';
import { processErrors } from '../../helpers/categoryHelpers/processErrors';
import { Toast } from '../../helpers/toast';

type Props = { customerAssetAccountsId: number; reloadTable: () => void };

const validationSchema = Yup.object().shape({
    countryCode: Yup.string().required('Required').nullable(),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    cardNumber: Yup.string().required('Required'),
    expirationDate: Yup.string().required('Required'),
    cvv: Yup.string().required('Required'),
});

type FormState = {
    customerAssetAccountsId: number;
    countryCode: string | null;
    firstName: string;
    lastName: string;
    cardNumber: string;
    expirationDate: string;
    cvv: string;
};

export const VisaDirectModal = ({ customerAssetAccountsId, reloadTable }: Props) => {
    const dispatch = useDispatch();

    const handleCloseModal = () => dispatch(closeModal());

    const { data: countries } = useFetch(endpoints.accounts.availablePayeeCountries, undefined, {
        details: [{ name: 'United States', countryISO3: 'USA', countryISO2: 'US' }],
    });
    const availableCountries =
        countries?.details.map((country) => ({
            label: country.name,
            value: country.countryISO3,
        })) ?? [];

    const handleSubmit = async (values: FormState, helpers: FormikHelpers<FormState>) => {
        try {
            const res = await api.post<ApiResponse>(endpoints.accounts.addCardPayee, values);

            if (res.data.status === '1') {
                reloadTable();
                handleCloseModal();
                dispatch(completeUIUpdate());
            } else throw new Error();
        } catch (err) {
            const GENERIC_FAILURE = 'Failed to reigster card';
            if (isAxiosErrorHandled(err) && err.response.data.errors.length > 0) {
                const errors = err.response.data.errors;
                const errorsObj = processErrors(errors);
                helpers.setErrors(errorsObj);

                if (errorsObj['error_Label']) {
                    Toast.openGenericErrorToast();
                }
            } else {
                Toast.openErrorToast(GENERIC_FAILURE);
            }
        }
    };

    const initialValues: FormState = {
        customerAssetAccountsId,
        countryCode: null,
        firstName: '',
        lastName: '',
        cardNumber: '',
        expirationDate: '',
        cvv: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ values, isSubmitting, setFieldValue }) => (
                <Form>
                    <Modal
                        title="Visa Direct"
                        className="VisaDirectModal"
                        backgroundClassName="VisaDirectModalBg"
                        customButtons={
                            <div className="ModalNavigation">
                                <Button
                                    priority="secondary"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </Button>
                                <Button priority="primary" type="submit" disabled={isSubmitting}>
                                    Register
                                </Button>
                            </div>
                        }
                    >
                        <h2>Register Card</h2>
                        <div className="Layout">
                            <FormTextField
                                field="firstName"
                                label="First Name"
                                disabled={isSubmitting}
                            />
                            <FormTextField
                                field="lastName"
                                label="Last Name"
                                disabled={isSubmitting}
                            />
                            <FormTextField
                                wrapperClassname="CardNumber"
                                field="cardNumber"
                                label="Debit Card Number"
                                disabled={isSubmitting}
                                onChange={(e) => {
                                    e.preventDefault();

                                    const { value } = e.target;
                                    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                                    if (regex.test(value)) {
                                        setFieldValue('cardNumber', value, false);
                                    } else {
                                        setFieldValue('cardNumber', values.cardNumber, true);
                                    }
                                }}
                            />
                            <div className="FormBuilderComponent SideBySide">
                                <FormDateField
                                    field="expirationDate"
                                    label="Expiry Date"
                                    datePickerProps={{
                                        dateFormat: 'MM/yy',
                                        minDate: new Date(),
                                        showMonthYearPicker: true,
                                        placeholderText: 'MM/yy',
                                        disabled: isSubmitting,
                                    }}
                                />
                            </div>
                            <FormTextField
                                field="cvv"
                                label="CVV"
                                disabled={isSubmitting}
                                onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    const regex = /^\d{1,4}$/;
                                    if (regex.test(value) || !value) {
                                        setFieldValue('cvv', value, false);
                                    } else {
                                        setFieldValue('cvv', values.cvv, true);
                                    }
                                }}
                            />
                            <FormSingleSelectField
                                fieldName="countryCode"
                                options={availableCountries}
                                label="Country"
                                dropdownProps={{ isDisabled: isSubmitting }}
                            />
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
