import { useEffect, useMemo, useRef, useState } from 'react';
import { SegmentedProgressBar } from '../../../../components/SegmentedProgressBar/SegmentedProgressBar';
import { ErrorCountry, Recipient } from './Recipient';
import ArrowIcon from 'assets/Icon_Form_Arrow_02.png';
import styles from './MakePayment.module.scss';
import { PayeeFormState } from '../EditPayee';
import { PaymentDetails, PaymentDetailsForm } from './PaymentDetails';

import { ApiResponse, useFetch } from '../../../../api';
import { endpoints } from '../../../../endpoints.config';
import { MoreDetails, MoreDetailsForm } from './MoreDetails';
import { FXTranferPaymentForm, ReviewPayment } from './ReviewPayment';
import { useGetFiatAccountDetails } from '../../../../helpers/useGetFiatAccountDetails';
import { ProductType } from '../../../../components/sideMenu/SideMenu';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { useCreatePayeeInitalValues } from '../helpers';
import DBSPaymentAgreementModal from 'components/DBSPaymentAgreement/DBSPaymentAgreementModal';
import { AmountPurposeForm, FXAmountPurposeForm } from './amountPurposeModel';
import { AmountPurpose } from './AmountPurpose';
import { Toast } from 'helpers/toast';

const STEPS = [
    { label: 'Recipient', stepNumber: 1 },
    { label: 'Payment Details', stepNumber: 2 },
    { label: 'Amount & Purpose', stepNumber: 3 },
    { label: 'More Details', stepNumber: 4 },
    { label: 'Review', stepNumber: 5 },
];

export type FeeOption = {
    bankFeeSplitType: string;
    bankFeeSplitTypesId: number;
    minFee: number;
    maxFee: number;
    feePercent: number;
    tempHoldFee?: string;
    fixedAdditionalFee?: number;
};

export type AccountFeeInfo = {
    balance: number | null;
    transferTypes: {
        transferType: string;
        feeOptions: FeeOption[];
    }[];
};

export type MakePaymentForm = PayeeFormState & {
    accountType: string;
    allowedTransferTypes: string;
    bInternational: boolean;
    id: number; // payee id
    bSavePayee: boolean;
    customerAssetAccountsId: number;
} & PaymentDetailsForm &
    FXAmountPurposeForm &
    MoreDetailsForm;

export type PaymentStepProps = {
    nextStep: (values?: any) => void;
};

type Props = {
    goBack: () => void;
    payee?: Pick<
        PayeeFormState,
        | 'payeeType'
        | 'countryCode'
        | 'addressCountryCode'
        | 'name'
        | 'accountName'
        | 'payeesReference'
        | 'routingNumber'
        | 'accountNumber'
        | 'swiftNumber'
        | 'iban'
    > & {
        id: number;
        allowedTransferTypes: string;
        bInternational: boolean;
        accountType: string;
    };
};

export type CurrencyOptionResponse = {
    name: string;
    id: number;
    code: string;
}[];

export type CountryOptionResponse = {
    name: string;
    countryISO3: string;
    countryISO2: string;
    states: StateOptionResponse[] | null;
    crbErrorCode: string | null;
    dbsErrorCode: string | null;
    bankingCircleErrorCode: string | null;
    gldbErrorCode: string | null;
};

export type StateOptionResponse = {
    id: number;
    isoCode: string;
    name: string;
};

export type CountryOptions = SelectOption & {
    states: SelectOption[] | null;
};

export type PayeeDetails = {
    currencyCode: string;
    buyPrice: number;
    sellPrice: number;
    feePercentage: number;
    sourceAmountMinFee: number;
    destinationAmountMinFee: number;
};

export const useFetchPayeeCountries = () => {
    const accountDetails = useGetFiatAccountDetails();
    const isCrb = accountDetails?.productDisplayName === ProductType.CRB;
    const isBC = accountDetails?.productDisplayName === ProductType.BC;
    const isGLDB = accountDetails?.productDisplayName === ProductType.GLDB;
    const isDbs =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;

    const { data: countries } = useFetch<{ details: CountryOptionResponse[] }>(
        endpoints.accounts.availablePayeeCountries,
        undefined,
        {
            details: [
                {
                    name: 'United States',
                    countryISO3: 'USA',
                    countryISO2: 'US',
                    states: null as StateOptionResponse[] | null,
                    crbErrorCode: '',
                    dbsErrorCode: '',
                    bankingCircleErrorCode: '',
                    gldbErrorCode: '',
                },
            ],
        }
    );
    const availableCountries =
        countries?.details.map((country) => ({
            label: country.name,
            value: country.countryISO3,
            states: country.states
                ? country.states.map((s) => ({ label: s.name, value: s.isoCode }))
                : null,
            countryISO2: country.countryISO2,
        })) ?? [];
    const errorCountries: ErrorCountry[] = (countries?.details ?? [])
        .filter(
            (country) =>
                (isCrb && !!country.crbErrorCode) ||
                (isDbs && !!country.dbsErrorCode) ||
                (isBC && !!country.bankingCircleErrorCode) ||
                (isGLDB && !!country.gldbErrorCode)
        )
        .map((country) => ({
            countryISO3: country.countryISO3,
            name: country.name,
            errorCode: isCrb
                ? country.crbErrorCode
                : isDbs
                ? country.dbsErrorCode
                : isGLDB
                ? country.gldbErrorCode
                : country.bankingCircleErrorCode,
        }));

    return { availableCountries, errorCountries };
};
export const useFetchPayeeCurrencies = () => {
    const accountDetails = useGetFiatAccountDetails();

    const { data: currencies } = useFetch<{ details: CurrencyOptionResponse }>(
        endpoints.accounts.availablePayeeCurrencies,
        { params: { customerAssetAccountsId: accountDetails?.id } },
        {
            details: [],
        }
    );
    const availableCurrencies =
        currencies?.details?.map((currency) => ({
            label: currency.name,
            value: currency.code,
        })) ?? [];
    return { availableCurrencies };
};
export const useFetchPayeeDetails = (payeeId: number | undefined) => {
    const { data: payeeDetails, error: payeeDetailsError } = useFetch<{ details: PayeeDetails }>(
        endpoints.accounts.getPayeeDetails,
        { params: { id: payeeId } },
        undefined,
        !payeeId
    );
    return { payeeDetails, payeeDetailsError };
};

export const MakePayment: React.FC<Props> = ({ goBack, payee }) => {
    const [steps, setSteps] = useState(payee ? STEPS.slice(1) : STEPS);
    const [currentStep, setCurrentStep] = useState(steps[0].stepNumber);

    const isDBSAgreementReadRef = useRef<boolean>(false);
    const [showDBSAgreement, setShowDBSAgreement] = useState<boolean>(false);
    const accountDetails = useGetFiatAccountDetails();
    const [payeeId, setPayeeId] = useState(payee?.id);
    const { initialValues: payeeData } = useCreatePayeeInitalValues(payeeId);
    const { availableCountries, errorCountries } = useFetchPayeeCountries();
    const [bFxTransfer, setBFxTransfer] = useState<boolean>(false);

    const { payeeDetails, payeeDetailsError } = useFetchPayeeDetails(payeeId);

    const [paymentData, setPaymentData] = useState<Partial<MakePaymentForm>>(payee ?? {});

    const {
        data,
        error,
        loading: loadingPurposes,
        reload,
    } = useFetch<ApiResponse<{ purposeCodes: { code: string; description: string }[] | null }>>(
        endpoints.accounts.getPurposeCodes,
        {
            params: {
                customerAssetAccountsId: accountDetails?.id + '',
                countryCode: paymentData.countryCode,
                currencyCode: paymentData.currencyCode,
            },
        },
        undefined,
        !paymentData.countryCode || !payeeData
    );

    const {
        data: feeDetails,
        loading: loadingFees,
        reload: reloadFees,
    } = useFetch<ApiResponse<AccountFeeInfo>>(endpoints.accounts.feeDetails, {
        params: {
            customerAssetAccountsId: accountDetails?.id + '',
            payeesId: payee ? payee.id + '' : paymentData.id,
        },
        withCredentials: false,
    });
    useEffect(() => {
        if (payeeDetailsError) Toast.openErrorToast('Error fetching payee details');
    }, [payeeDetailsError]);
    useEffect(() => {
        reloadFees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentData.id, payee?.id]);

    useEffect(() => {
        setPaymentData((prev) => ({
            ...prev,
            ...payeeData,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payeeData]);

    useEffect(() => {
        if (error) reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    useEffect(() => {
        if (currentStep === 3 && !isDBSAgreementReadRef.current) {
            setShowDBSAgreement(true);
        }
    }, [currentStep]);

    const purposes = (data?.details.purposeCodes ?? []).map((item) => ({
        label: item.description,
        value: item.code,
    }));

    const prevStep = () => {
        if (currentStep === steps[0].stepNumber) {
            goBack();
        } else setCurrentStep((prev) => prev - 1);
    };
    const nextStep = (values: any) => {
        if (currentStep === 1) setPayeeId(values?.id);
        if (currentStep === steps[steps.length - 1].stepNumber) {
            goBack();
        } else {
            setPaymentData((prev) => ({
                ...prev,
                ...values,
            }));
            setCurrentStep((prev) => prev + 1);
        }
    };

    const step = STEPS.find((step) => step.stepNumber === currentStep)!;

    const handleEditPayee = () => {
        setSteps(STEPS);
        setCurrentStep(STEPS[0].stepNumber);
    };
    const paymentAgreementProps = useMemo(() => {
        return {
            onConcentAccepted: () => {
                isDBSAgreementReadRef.current = true;
                setShowDBSAgreement(false);
            },
            close: () => {
                setCurrentStep((prev) => prev - 1);
                setShowDBSAgreement(false);
            },
        };
    }, []);

    const isDbs =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;

    return (
        <div className={`MoveMoneySubPage ${styles.MakePaymentWrapper}`}>
            <div className={styles.MakePayment}>
                <h1>Make payment</h1>
                <SegmentedProgressBar currentStepNumber={currentStep} steps={steps} />
                <div className={styles.MakePaymentHeader}>
                    <button onClick={prevStep} type="button" className={styles.BackButton}>
                        <img alt="Back" src={ArrowIcon} />
                    </button>
                    <h2>{step.label}</h2>
                </div>

                {currentStep === 1 && (
                    <Recipient
                        availableCountries={availableCountries}
                        errorCountries={errorCountries}
                        initialValues={{
                            id: paymentData.id,
                            payeeType: paymentData.payeeType,
                            countryCode: paymentData.countryCode,
                            accountName: paymentData.accountName,
                            name: paymentData.name,
                            payeesReference: paymentData.payeesReference,
                            routingNumber: paymentData.routingNumber,
                            swiftNumber: paymentData.swiftNumber,
                            intermediaryBic: paymentData.intermediaryBic,
                            accountNumber: paymentData.accountNumber,
                            iban: paymentData.iban,
                            bankName: paymentData.bankName,
                            addressLine1: paymentData.addressLine1,
                            addressLine2: paymentData.addressLine2,
                            townCity: paymentData.townCity,
                            state: paymentData.state,
                            postCode: paymentData.postCode,
                            addressCountryCode: paymentData.addressCountryCode,
                            currencyCode: paymentData.currencyCode,
                            transmitter: paymentData.transmitter,
                            bSavePayee: true,
                        }}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 2 && (
                    <PaymentDetails
                        initialValues={{
                            paymentReference: paymentData.paymentReference ?? '',
                            ...(paymentData.transferType && {
                                transferType: paymentData.transferType,
                            }),
                            beneficiaryReference: paymentData.beneficiaryReference ?? null,
                        }}
                        paymentMethods={paymentData.allowedTransferTypes ?? ''}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 3 && (
                    <>
                        <AmountPurpose
                            accountFeeInfo={
                                feeDetails?.details ?? {
                                    balance: null,
                                    transferTypes: [],
                                }
                            }
                            loading={loadingFees || loadingPurposes}
                            purposes={purposes}
                            bInternational={!!paymentData.bInternational}
                            transferType={paymentData.transferType!}
                            accountType={paymentData.accountType}
                            bFxTransfer={bFxTransfer}
                            setBFxTransfer={setBFxTransfer}
                            initialValues={{
                                amount: paymentData.amount ?? 0,
                                purpose: paymentData.purpose ?? '',
                                purposeOther: paymentData.purposeOther ?? null,
                                ...(paymentData.feeId && { feeId: paymentData.feeId ?? 0 }),
                            }}
                            nextStep={nextStep}
                            sourceCurrency={accountDetails?.currencyCode}
                            destinationCurrency={
                                payeeDetails?.details.currencyCode ?? paymentData.currencyCode
                            }
                            estimatedAmount={paymentData.estimatedAmount ?? 0}
                            destinationAmount={paymentData.destinationAmount ?? 0}
                            estimatedDestinationAmount={paymentData.estimatedDestinationAmount ?? 0}
                            payeeDetails={payeeDetails?.details}
                        />
                        {paymentData.transferType === 'SWIFT' && isDbs && showDBSAgreement && (
                            <DBSPaymentAgreementModal {...paymentAgreementProps} />
                        )}
                    </>
                )}
                {currentStep === 4 && (
                    <MoreDetails
                        initialValues={{
                            attachments: paymentData.attachments ?? [],
                            notes: paymentData.notes ?? '',
                        }}
                        nextStep={nextStep}
                    />
                )}
                {currentStep === 5 && (
                    <ReviewPayment
                        availableCountries={availableCountries}
                        purposes={purposes}
                        accountFeeInfo={
                            feeDetails?.details ?? {
                                balance: 0,
                                transferTypes: [],
                            }
                        }
                        data={
                            bFxTransfer
                                ? (paymentData as MakePaymentForm)
                                : (paymentData as FXTranferPaymentForm)
                        }
                        nextStep={nextStep}
                        handleEditPayee={handleEditPayee}
                        payeeId={payee?.id}
                        customerAssetAccountsId={accountDetails?.id}
                        sourceCurrency={accountDetails?.currencyCode}
                        destinationCurrency={
                            payeeDetails?.details.currencyCode ?? paymentData.currencyCode
                        }
                        bFxTransfer={bFxTransfer}
                    />
                )}
            </div>

            {paymentData && paymentData.name && (
                <div className={styles.PayeeNameWrapper}>
                    <div className={styles.PayeeInitials}>
                        {paymentData.name.split(' ').reduce((prev, cur, i, arr) => {
                            if (i === 0 || i === arr.length - 1) {
                                return prev + cur[0].toUpperCase();
                            } else return prev;
                        }, '')}
                    </div>
                    <div className={styles.PayeeName}>{paymentData.name}</div>
                </div>
            )}
        </div>
    );
};
