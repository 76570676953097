import React from 'react';
import { FXTranferPaymentForm } from './ReviewPayment';
import { formatCurrency } from 'helpers/fiatFormatter';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import RefreshIcon from 'assets/ibanera/refresh.svg';
type Props = {
    reviewErrorMessage: string;
    transferDetails: FXTranferPaymentForm;
    sourceCurrency: string;
    destinationCurrency: string;
    exactPrice: 'Left' | 'Right';
    refreshReview: () => void
};
export const FXOrderSummary: React.FC<Props> = ({
    reviewErrorMessage,
    transferDetails,
    sourceCurrency,
    destinationCurrency,
    exactPrice,
    refreshReview
}) => {
    return (
        <div className="CurrencyExchangeModal">
            <h4>Exchange Summary</h4>

            <div className="BuySellAmounts">
                <div>
                    <h4>{`You're sending ${exactPrice === 'Left' ? 'exactly' : ''}`}</h4>
                    <h3>
                        {formatCurrency(
                            transferDetails.amount ?? transferDetails.estimatedAmount,
                            sourceCurrency,
                            undefined,
                            {
                                currencyDisplay: 'code',
                            }
                        )}
                    </h3>
                    {transferDetails.exchangeType === 'Receive' && (
                        <span>({transferDetails.amountLessFees} after fees)</span>
                    )}
                </div>
                {!reviewErrorMessage &&
                    <div>
                        <h4>{`Recipient will receive ${exactPrice === 'Right' ? 'exactly' : ''}`}</h4>
                        <h3>
                            {formatCurrency(
                                transferDetails.destinationAmountLessFees,
                                destinationCurrency,
                                undefined,
                                {
                                    currencyDisplay: 'code',
                                }
                            )}
                        </h3>
                        {transferDetails.exchangeType === 'Send' && (
                            <span>({transferDetails.estimatedDestinationAmount} minus fees)</span>
                        )}
                    </div>
                }

            </div>
            <div className="ExchangeRate ExchangeRateConfirm">
                {reviewErrorMessage && <div className="ErrorContainerReview">
                    <div className="ErrorText OptionLabel">{reviewErrorMessage}</div>
                    <div className="RefreshBtnReview" onClick={refreshReview}>
                        <img
                            src={RefreshIcon} title='Refresh'
                            alt="icon"
                            className="WarningIconReview"
                        />
                    </div>
                    </div>}
                {!reviewErrorMessage && transferDetails?.quotedFxRate && <div>Quoted Price:</div>}
                <div className="Rates">
                    {!reviewErrorMessage && transferDetails?.quotedFxRate && (
                        <>
                            <p>
                                {sourceCurrency} 1 <span className="c1">{'>'}</span>
                                <span className="c2">{'>'}</span>
                                <span className="c3">{'>'}</span> {destinationCurrency} ~{' '}
                                {/* {roundToPrecision(parseFloat(transferDetails.quotedFxRate), 6)} */}
                                {roundToPrecision(transferDetails.quotedFxRate, 6)}
                            </p>
                            <p>
                                {destinationCurrency} 1 <span className="c1">{'>'}</span>
                                <span className="c2">{'>'}</span>
                                <span className="c3">{'>'}</span> {sourceCurrency} ~{' '}
                                {/* {roundToPrecision(1 / parseFloat(transferDetails.quotedFxRate), 6)} */}
                                {roundToPrecision(1 / transferDetails.quotedFxRate, 6)}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
